.header {
    pointer-events: none;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    color: var(--color-text);
    &_white-right {
        .header__lang {
            color: var(--color-bg);
        }
    }
    &__container {
        // padding: em(25) em(80) em(25) em(40);
        padding: em(25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include r(1023) {
            padding-right: em(40);
        }
        a {
            color: currentColor;
            text-transform: uppercase;
            transform: $transition-base;
            &:not(.is-active) {
                text-decoration: none;
            }
            &.is-active {
                @include font-extra;
                text-decoration: underline;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .logo {
        pointer-events: all;
        display: flex;
        svg {
            width: em(167);
            height: em(33);
        }
    }
    &__lang {
        pointer-events: all;
        ul {
            @extend %reset-list;
            @include r(1023) {
                font-size: calc(var(--lgSize) * 3);
                padding: 1em 2em;
                position: relative;
                color: var(--color-bg);
                &::after {
                    content: "X";
                    position: absolute;
                    left: 0.5em;
                    bottom: 0.5em;
                    line-height: 1.4;
                    width: 1.4ch;
                    text-align: center;
                    color: var(--color-bg);
                    font-size: 0.4em;
                }
            }
        }
        li {
            display: inline-block;
            font-size: var(--smSize);
            @include r(1023) {
                color: inherit;
                font-size: inherit;
                display: block;
                text-align: center;
                // @media (orientation: portrait) and (max-width: 767px) {
                //     font-size: calc(var(--lgSize) * 1.5);
                // }
            }
            &:not(:first-child) {
                @include rmin(1024) {
                    margin-left: 0.45em;
                }
            }
        }
        @include r(1023) {
            position: absolute;
            bottom: auto;
            right: 0;
            top: 0;
            transform: translateY(10px);
            opacity: 0;
            transition: all $transition-slow;
            background-color: var(--color-text);
            pointer-events: none;
        }
    }
    &__trigger {
        pointer-events: all;
        @include rmin(1024) {
            display: none;
        }
        width: 2em;
        height: 2px;
        background-color: var(--color-text);
        position: relative;
        &::before,
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: currentColor;
            position: absolute;
            left: 0;
        }
        &::before {
            bottom: 6px;
        }
        &::after {
            top: 6px;
        }

        &.is-open {
            & ~ .header__lang {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }
}

// .testP{
//     display: block;
//     width: 100%;
//     border: 1px solid red;
//     & > *{
//         width: 100%;
//     }
// }

.flatpickr{
    &-calendar{
        max-height: fit-content;
        font-size: var(--basicSize);
        background: none;
        width: auto;
        line-height: 1.3;
        box-shadow: none;
        border-radius: 0;
        @include r(1023){
            z-index: 1;
        }
    }
    &-months{
        margin-bottom: 1em;
        position: relative;
        height: 2em;
        &::after{
            content: '';
            position: absolute;
            width: em(56,22);
            height: em(5,22);
            background-color: currentColor;
            left: 0;
            bottom: -.5em;
        }
        .flatpickr-month{
            height: auto;
        }
    }
    &-current-month{
        width: 100%;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: em(34,20);
        .numInputWrapper{
            width: auto;
            flex: 1;
            text-align: right;
            position: relative;
            height: 1em;
            @include r(767){
                height: 1.2em;
            }
            &::before{
                content: "";
                position: absolute;
                width: .5em;
                height: 0.5em;
                border: .15em solid;
                left: -.6em;
                top: .3em;
                transform: rotate(45deg);
                border-left-color: transparent;
                border-bottom-color: transparent;
                font-size: .86em;
                pointer-events: none;
            }
            &:hover{
                background-color: transparent;
            }
            .numInput{
                width: 4ch;
                font-weight: 500;
                padding-left: 0;
                height: 1em;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                &[type=number] {
                    -moz-appearance: textfield;
                }

            }
            .arrowUp,
            .arrowDown{
                display: none;
            }
        }
    }
    &-prev-month,
    &-next-month{
        display: none;
    }
    &-current-month select.flatpickr-monthDropdown-months{
        padding-left: 0;
        text-transform: uppercase;
        font-weight: bold;
        -webkit-tap-highlight-color: transparent;
        appearance: none;
        &:hover{
            background-color: transparent;
        }
        option{
            text-transform: capitalize;
            transform: scale(.8);
            font-size: 90%;
        }
    }
    &-weekdays{
        height: em(28);
    }
    &-weekdaycontainer{

        .flatpickr-weekday{
            text-transform: lowercase;
            color: var(--color-text);
        }

    }

    &-days{
        max-width: 22em;
        width: 100%;
        .dayContainer{
            width: 100%;
            min-width: 1px;
            max-width: 100%;
        }
        .flatpickr-day{
            margin-bottom: 0.7em;
            border-radius: 0;
            border-color: currentColor;
            border-right-color: transparent;
            height: 2.6em;
            line-height: 1.2;
            max-width: 14.2857143%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all $transition-base;
            &:nth-child(7n+7){
                border-right-color: currentColor;
            }
            // &.nextMonthDay{
            //     display: none;
            // }
            &.flatpickr-disabled{
                border-color: var(--color-text);
                background-color: var(--color-text);
                color: var(--color-bg);
            }
            &.flatpickr-disabled2{
                border-color: var(--color-gray);
                background-color: var(--color-gray);
                color: var(--color-bg);
            }
            &.selected{
                background-color: var(--color-primary);
                border-color: var(--color-text);
                // box-shadow: inset .5px -.5px 0 2px var(--color-text);
                box-shadow: inset 0.25px -.25px 0 1px var(--color-text);
                color: var(--color-text);
            }
            &:hover{
                &:not(.flatpickr-disabled):not(.flatpickr-disabled2):not(.selected){
                    background-color: var(--color-primary-light);
                }
                &.flatpickr-disabled{
                    border-color: var(--color-text);
                    border-right-color: transparent;
                    background-color: var(--color-text);
                    color: var(--color-bg);
                    &:nth-child(7n+7){
                        border-right-color: var(--color-text);
                    }
                }
                &.flatpickr-disabled2{
                    border-color: var(--color-gray);
                    border-right-color: transparent;
                    background-color: var(--color-gray);
                    color: var(--color-bg);
                    &:nth-child(7n+7){
                        border-right-color: var(--color-gray);
                    }
                }
            }
        }
    }
}
.flatpickr-calendar.inline, .flatpickr-calendar.open{
    max-height: initial;
}


.flatpickr-day.flatpickr-disabled2, .flatpickr-day.flatpickr-disabled2:hover{
    // cursor: not-allowed;
    pointer-events: none;
}


// just for fun window calendar hover effect
.flatpickr-innerContainer{
    margin-bottom: .7em;
    position: relative;
    overflow: hidden;
    .flatpickr-rContainer{
        position: relative;
        z-index: 5;
    }
    .flatpickr-weekdays{
        background: repeating-linear-gradient(to right, transparent, white 1px, white calc(14% - 1px), transparent 14.2857143%);
        border-top: 1px solid transparent;
        background-clip: content-box;
    }
}
#lightbulb{
    top: 100%;
    left: 100%;
    position: absolute;
    width: 15rem;
    height: 15rem;
    z-index: 4;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0px);
    transition: none;
    background: radial-gradient(at center center, rgba(17, 17, 17, 0.5) 0%, rgba(17, 17, 17, 0) 50%);
}
.flatpickr-days .flatpickr-day{
    background-color: var(--color-bg);
    padding: 1px;
    background-clip: content-box;
    position: relative;
    &:hover{
        &:not(.flatpickr-disabled):not(.flatpickr-disabled2):not(.selected){
            background-color: var(--color-bg);
            background-clip: content-box;
        }

    }
    &.flatpickr-disabled,
    &.flatpickr-disabled2{
        padding: 0;
    }
    &::after{
        content: '';
        position: absolute;
        width: calc(100% + 4px);
        left: -2px;
        height: .7em;
        top: calc(100% + 1px);
        background-color: var(--color-bg);
    }
    &.today{
        // padding: 0;
        // background-color: var(--color-primary);
        // color: var(--color-bg);
        // border-color: var(--color-primary);
        // &:hover:not(.flatpickr-disabled):not(.selected){
        //     background-color: var(--color-primary);
        //     color: var(--color-bg);
        //     border-color: var(--color-primary);
        // }
    }
}

.welcome-page{
    .bg-outside{
        right: 0;
        @include rmin(769){
            width: calc(100vw - 10em);
        }
        @include r(768){
            height: 67vh;
            img{
                object-position: left;
            }
        }
        @include r(475){
            height: 72.1vh;
            img {
                object-position: 25%;
            }
        }
        @include r(375){
            height: 70.4vh;
        }
    }
    .ocean-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(var(--basicUnit)*61);
        height: calc(var(--basicUnit)*20.789);
        background-color: var(--color-primary);
        transform: rotate(-90deg) translateX(-40vh) translateY(-12vw);
        @include r(768){
            width: calc(var(--basicUnit)*43);
            height: calc(var(--basicUnit)*13.789);
            transform: rotate(-90deg) translateX(-30vh) translateY(-17vw);
        }
        @include r(475){
            transform: rotate(-90deg) translateX(-30vh) translateY(-20vw);
        }
        @include r(375){
            transform: rotate(-90deg) translateX(-30vh) translateY(-21vw);
        }

        @media (max-width: 1023px) and (orientation: landscape){
            transform: rotate(-90deg) translateX(-37vh) translateY(-12vw);
        }
        .icon-ocean{
            // width: calc(var(--basicUnit)*45);
            width: 85%;
        }
        @media only screen and (min-width: 1200px) and (max-height: 569px){
            width: calc(var(--basicUnit)*49);
            height: calc(var(--basicUnit)*15.789);
            transform: rotate(-90deg) translateX(-40vh) translateY(-6vw);
        }
    }
    .full-wrapper{
        @include r(768){
            justify-content: flex-end;
        }
        &__part{
            width: 50%;
            &-left{
                @include r(768){
                    position: absolute;
                    left: 0;
                }
            }
            &-right{
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 4em;
                @include r(768){
                    max-width: calc(100vw - 7em);
                    flex: 1 1 100%;
                    align-items: flex-end;
                }
                @include rmin(1024){
                    .footer-visible &{
                        height: 100vh;
                        overflow-y: auto;
                        padding-bottom: var(--footerHeight);
                    }
                }
            }
        }
        &__inner{
            @include rmin(769){
                width: calc(var(--basicUnit)*35.395);
            }
            &-block{
                padding: em(70) em(50) em(60);
                margin-bottom: em(60);
                color: var(--color-bg);
                background-color: rgba(0,0,0, .45);
                @include r(768){
                    padding: em(60) em(30) em(40);
                    margin-bottom: em(76);
                }
                @include r(380){
                    padding: em(60) em(30) em(55);
                }
                h3{
                    letter-spacing: -0.9px;
                    line-height: 1.1;
                    @include r(768){
                        font-size: calc(var(--basicUnit) * 8);
                        line-height: 1;
                    }
                    @include r(475){
                        margin-bottom: 1.2em;
                    }
                    @include r(375){
                        font-size: calc(var(--basicUnit) * 7);
                    }
                }
            }
        }
        .underline{
            background-color: var(--color-bg);
            @include r(768){
                width: calc(var(--basicUnit)*11.0699);
                height: calc(var(--basicUnit)/1.23);
            }
        }
        .link-page{
            @include rmin(768){
                @media (max-width: 1023px) and (orientation: landscape) {
                    position: relative;
                    &::after{
                        position: absolute;
                        width: 100%;
                        content: '';
                        height: 200%;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                @media (min-width: 1024px) {
                    position: relative;
                    @media (max-height: 600px) {
                        margin-top: -40px;
                        margin-bottom: 30px;
                    }
                    &::after{
                        position: absolute;
                        width: 115%;
                        content: '';
                        height: 230%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
            @include r(768){
                padding: em(25) em(40);
                justify-content: flex-end;
                position: absolute;
                bottom: 0;
                left: -7em;
                width: 100vw;
                max-width: inherit;
                background-color: var(--color-text);
                color: var(--color-bg);
            }
            .icon-arrow{
                position: relative;
                right: em(-8);
                width: calc(var(--basicUnit)*3.226);
                height: calc(var(--basicUnit)*1.703);
                transition: all .3s linear;
                @include r(768){
                    stroke: var(--color-bg);
                    width: calc(var(--basicUnit)*5.226);
                    height: calc(var(--basicUnit)*2.703);
                }
            }
            &:hover{
                .icon-arrow{
                    transform: rotate(-90deg) translateY(em(10));
                }
            }
        }
    }
    @include r(1023){
        .full-wrapper{
            &_overview{
                position: relative;
                width: 100%;
                height: 100%;
                .full-wrapper__part-right{
                    margin: 0 0 0 auto;
                    width: 76vw;
                    max-width: 76vw;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }
                .link-page{
                    width: 100vw;
                    right: 0;
                    position: fixed;
                    bottom: 0;
                }
            }
        }
    }



}
.particular {
    &__left {
        flex: 1 1 62.7%;
        max-width: 62.7%;
        height: 100%;
        padding-top: 17.25vh;
        background: var(--color-bg);
        // transition: all .6s linear,
        //     padding .6s linear;

        @media only screen and (max-height: 600px) {
            padding-top: 10.25vh;
        }
        @include r(1023) {
            flex: 1 1 100%;
            max-width: 100%;
            padding-top: 11.7vh;
        }
        .row {
            display: block;
            position: relative;
            text-decoration: none;
            color: var(--color-text);
            padding: 1.75em 3.65em 3.65em;
            @include r(768) {
                padding: 1.75em 7em 3.65em 2.35em;
            }
            h2 {
                margin-bottom: 0.75em;
                @include r(768) {
                    font-size: calc(var(--basicUnit) * 11.5);
                    margin-bottom: 0.45em;
                }
            }
            p {
                margin-top: 0;
                line-height: 1.5;
            }
            .link-page {
                position: absolute;
                border: none;
                outline: none;
                background: none;
                right: 5em;
                bottom: 4.65em;
                transform: translate(0);
                // transition: transform .23s ease-in;
                // transition: all .6s linear,
                //     transform .6s ease-in ;
                // &:hover:not([disabled]) {
                //     transform: translateX(2em);
                // }
                &__title {
                    @include rmin(769) {
                        display: none;
                    }
                    @include r(768) {
                    }
                }
                @include r(768) {
                    right: 2.35em;
                    bottom: 2.35em;
                    .icon-arrow {
                        position: relative;
                        right: em(-8);
                        width: calc(var(--basicUnit) * 6.4);
                        height: calc(var(--basicUnit) * 3);
                        // transition: all .3s linear;
                    }
                }
            }
            &__client {
                visibility: visible;
                opacity: 1;
                transition: background-color 0.23s;
                // transition: all .6s linear,
                //     visibility .6s ease-in,
                //     opacity .6s linear,
                //     margin .6s ease-in;
                p {
                    margin: 0;
                    @include rmin(769) {
                        max-width: calc(var(--basicUnit) * 24.11);
                    }
                }
                // &:hover{
                //     @include rmin(769){
                //         .link-page{
                //             transform: translateX(1em);
                //         }
                //     }
                // }
                @include r(768) {
                    height: 40vh;
                }
            }
            &__business {
                padding-top: 3.38em;
                transition: background-color 0.23s;
                // transition: all .6s linear,
                //     height .6s ease-in;
                @include r(768) {
                    height: 48.3vh;
                    padding-right: 2.35em;
                }
                h2 {
                    // transition: all .6s linear,
                    //     font-size .6s ease-in;
                }
                .underline {
                    position: absolute;
                    top: 0;
                    left: 3.65em;
                    background-color: var(--color-text);
                    // transition: all .6s linear,
                    //     top .6s linear,
                    //     width .6s linear,
                    //     height .6s linear;
                    @include r(768) {
                        left: 2.35em;
                        width: calc(var(--basicUnit) * 14.248);
                        height: calc(var(--basicUnit) / 1.45);
                    }
                }
                .text_lg {
                    font-weight: 700;
                    line-height: 1.2;
                    // transition: all .6s linear;
                }
                .form {
                    &__line {
                        flex-wrap: wrap;
                    }
                    &__field {
                        &.half {
                            // transition: all .6s linear,
                            //     padding-right .6s linear,
                            //     margin .6s linear,
                            //     max-width .6s linear;
                            &:first-child {
                                max-width: 35%;
                                // padding-right: 6vw;
                                @include r(768) {
                                    flex: 1 1 100%;
                                    max-width: 100%;
                                    // padding-right: 33vw;
                                    .text_lg {
                                        font-size: calc(var(--basicUnit) * 4.6);
                                    }
                                }
                            }
                            &:last-child {
                                max-width: calc(var(--basicUnit) * 22.22);
                                input {
                                    padding: em(18) em(20);
                                    text-align: center;
                                    font-weight: 900;
                                    @include r(475) {
                                        padding: em(12) em(20);
                                    }
                                    &:invalid {
                                        border-color: currentColor;
                                        background-color: transparent;
                                    }
                                    &:focus {
                                        outline: 1px dashed currentColor;
                                    }
                                }
                                @include r(768) {
                                    flex: 1 1 100%;
                                    max-width: 100%;
                                    padding: 0;
                                }
                            }
                        }
                        input {
                            color: var(--color-text);
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus{
                                border-color: var(--color-text);
                                -webkit-text-fill-color: var(--color-text);
                                transition: background-color 5000s ease-in-out 0s;
                            }
                        }
                    }
                }


                .link-page {
                    height: fit-content;
                    @include rmin(769) {
                        top: 14.8vh;
                        bottom: unset;
                    }
                    @include r(475) {
                        bottom: 3.35em;
                    }
                }
            }
            &:hover {
                background-color: var(--color-primary);
            }
            @media (max-width:1023px) and (orientation: landscape){
                &__business,
                &__client{
                    padding-right: 22vw;
                }
            }
        }
    }
    &__right {
        flex: 37.3%;
        max-width: 37.3%;
        height: 100%;
        background-color: var(--color-text);
        @include r(768) {
            flex: 1 1 100%;
            max-width: 100%;
            transform: translateY(0);
            // transition: all .4s linear;
        }
    }
    &-page {
        .icon-arrow,
        .link-page {
            transition: none;
        }
    }
}

.js-textfit,
.js-mustEqualFz {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.23s ease, transform 0.23s ease-in;
    &.is-visible {
        opacity: 1;
        transform: translateY(0);
    }
}
.js-textfit {
    width: calc(100vw - 4.7rem);
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

body.code-focus,
.full-wrapper__business {
    @include rIn(768,1023){
        @media (orientation: landscape) {
            display: flex;
        }
    }
    .row__client {
        visibility: hidden;
        opacity: 0;
        margin-top: -35vh;
        transform: scale(0.5) translate(-35vh, -5vh);
        @include r(768) {
            margin-top: -40vh;
        }
    }
    .particular__left {
        padding-top: 30vh;
        @media only screen and (max-height: 1100px) {
            padding-top: 25vh;
        }
        @media only screen and (max-height: 600px) {
            padding-top: 15vh !important;
        }
        @include r(768) {
            padding-top: 11.7vh !important;
        }
        @include r(475) {
            padding-top: 10vh !important;
        }

        @include r(1023){
            @media (orientation: landscape) and (min-width: 768px){
                flex: 1 1 62.7%;
                max-width: 62.7%;
            }
        }
    }
    .row__business {
        height: 80vh;
        @include r(768) {
            padding-top: 5.7em;
            height: 88.3vh;
        }
        @media (max-width: 1023px) and (orientation: portrait) {
            --line: 58%;
            background-image: linear-gradient(
                to bottom,
                var(--color-bg),
                var(--color-bg) var(--line),
                var(--color-text) var(--line),
                var(--color-text)
            );
            &:hover {
                background-image: linear-gradient(
                    to bottom,
                    var(--color-bg),
                    var(--color-bg) var(--line),
                    var(--color-text) var(--line),
                    var(--color-text)
                );
            }
        }
        @media (max-width: 1023px) and (orientation: landscape) {
            padding-top: 2em;
        }
        &:hover {
            background: none;
        }
        h2 {
            font-size: var(--h1Size);
            margin-left: -0.05em;
            @include rIn(476, 768) {
                font-size: calc(var(--basicUnit) * 17.5);
                margin-bottom: 0.75em;
            }
            @include r(475) {
                font-size: calc(var(--basicUnit) * 14.5);
                margin-bottom: 1.25em;
            }
            @media (max-width: 1023px) and (orientation: landscape) {
                margin-bottom: 0.2em !important;
            }
        }
        .underline {
            top: 30vh;
            width: calc(var(--basicUnit) * 8.423);
            height: calc(var(--basicUnit) * 1.434);
            @include r(768) {
                top: 32vh;
                width: calc(var(--basicUnit) * 14.248);
                height: calc(var(--basicUnit) * 1.834);
            }
            @include r(475) {
                top: 29vh;
            }
            @media (max-width: 1023px) and (orientation: landscape) {
                top: 42vh;
            }
        }
        .form__field {
            &.half {
                &:first-child {
                    @include rmin(769) {
                        max-width: 45%;
                        padding-right: 0;
                        margin-top: 0.85em;
                    }
                }
                &:last-child{
                    input{
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus{
                            border-color: var(--color-text);
                            -webkit-text-fill-color: var(--color-text);
                            transition: background-color 5000s ease-in-out 0s;
                            @media (max-width: 1023px) and (orientation: portrait){
                                border-color: var(--color-bg);
                                -webkit-text-fill-color: var(--color-bg);
                            }
                        }
                    }
                    @include rmin(769){
                        margin-left: 4em;
                    }
                    @include r(768) {
                        .flow-label {
                            display: block;
                            color: var(--color-bg);
                            transform: translateY(14vh);
                        }
                        input {
                            position: relative;
                            z-index: 1;
                            transform: translateY(14vh);
                            color: var(--color-bg);
                            border-color: var(--color-bg);
                            // transition: all .4s linear .2s;
                        }
                        @media (max-width: 1023px) and (orientation: landscape) {
                            .flow-label {
                                color: var(--color-text);
                            }
                            input {
                                color: var(--color-text);
                                border-color: var(--color-text);
                                max-width: 70%;
                            }
                        }
                    }
                    @include r(475) {
                        .flow-label {
                            transform: translateY(20vh);
                        }
                        input {
                            transform: translateY(20vh);
                        }
                    }
                }
            }
        }
        .icon-arrow {
            stroke: var(--color-bg);
            @include rmin(769) {
                width: calc(var(--basicUnit) * 16.85);
                height: calc(var(--basicUnit) * 9.0699);
            }
            @media (max-width: 1023px) and (orientation: portrait) {
                stroke: var(--color-bg);
            }
            @include r(1023) {
                stroke: var(--color-text);
            }
        }
        .link-page {
            z-index: 1;
            @include rmin(769) {
                transform: translate(41vw, 27vh);
                z-index: 1;
                &:hover:not([disabled]) {
                    transform: translate(42vw, 27vh);
                }
            }
            @media (max-width: 1023px) and (orientation: portrait) {
                color: var(--color-bg);
            }
            @media (max-width: 1023px) and (orientation: landscape) {
                color: var(--color-text);
            }
            &__title {
                // transition: all .4s linear;
                // color: var(--color-bg);
            }
        }
    }
    .particular__right {
        @include r(768) {
            transform: translateY(-32vh);
        }
    }
}

.main-page{
    width: 100vw;
    height: 100vh;
    background-color: var(--color-text);
    color: var(--color-bg);
    .intro{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        @include r(768){
            padding-top: em(80);
        }
        &__logo{
            padding: 0 4.2em;
            margin: 10em 0;
            .logo__white{
                width: calc(var(--basicUnit) * 77.0699);
                height: calc(var(--basicUnit) * 15.0699);
            }
            @include r(768){
                margin: 0 0 5em;
            }
            @include r(575) {
                padding: 0 1.2em;
            }
        }
        &__selects{
            padding: 0 4.2em;
            width: 100%;
            max-width: calc(var(--basicUnit) * 111.8);
            @include r(575){
                padding: 0 1.2em;
            }
            .text_lg{
                margin-top: 0;
                margin-left: .5em;
                @include r(768){
                    font-size: calc(var(--basicUnit) * 5.035);
                    margin-left: 0;
                    margin-bottom: em(5);
                }
            }
            .flag{
                width: calc(var(--basicUnit) * 8.423);
                height: calc(var(--basicUnit) * 2.0699);
                transition: all .3s linear;
                @include r(768){
                    transition: unset;
                    width: 100%;
                    height: auto;
                    max-height: calc(var(--basicUnit)*6.09);
                }
            }
        }
        .tabs {
            position: relative;
            @include r(768){
                padding: em(200) 0 0;
            }
            input {
                display: none;
                &:checked + label {
                    border: none;
                    opacity: 1;
                    .flag{
                        transform: scale(1.2);
                    }
                    @include r(768){
                        width: auto;
                        position: absolute;
                        left: 0;
                        top: 0;
                        .flag{
                            transform: scale(1);
                            width: calc(var(--basicUnit)*34.008);
                            height: calc(var(--basicUnit)*8.388);
                            margin-left: -5px;
                            max-height: unset;
                        }
                    }
                }
            }
            label {
                display: inline-block;
                margin-left: .7em;
                border: none;
                opacity: 0.3;
                transition: all .3s linear;
                &:hover {
                    cursor: pointer;
                }
                @include r(768){
                    width: 32%;
                    transition: unset;
                    margin-left: 0;
                    opacity: .55;
                }
            }
            &__lang {
                position: absolute;
                bottom: .3em;
                right: 0;
                width: 50%;
                height: calc(var(--basicUnit) * 2.0699);
                display: flex;
                align-items: flex-end;
                visibility: hidden;
                opacity: 0;
                z-index: -1;
                transition: all .3s linear,
                    visibility .3s ease-in,
                    opacity .3s ease-in;
                @include r(768){
                    width: 100%;
                    bottom: unset;
                    top: em(100);
                }
                &:before{
                    display: inline-block;
                    margin-bottom: .2em;
                    margin-right: 1em;
                    content: '';
                    width: 0%;
                    height: 2px;
                    background-color: var(--color-bg);
                    transition: all .6s linear,
                        width .6s linear;
                }
                a{
                    position: relative;
                    text-decoration: none;
                    color: var(--color-bg);
                    font-style: italic;
                    line-height: 1;
                    font-weight: 300;
                    white-space: nowrap;
                    transition: all .3s linear;
                    &::before{
                        position: absolute;
                        bottom: 0;
                        display: block;
                        content: '';
                        width: 0%;
                        height: 1px;
                        background-color: var(--color-bg);
                        transition: all .3s linear;
                    }
                    &:hover{
                        &:before{
                            width: 100%;
                        }
                    }
                }
                &-slash{
                    margin: 0 .5em;
                    line-height: 1;
                }
            }
            #tab1:checked ~ #lang1,
            #tab2:checked ~ #lang2,
            #tab3:checked ~ #lang3,
            #tab4:checked ~ #lang4 {
                visibility: visible;
                opacity: 1;
                z-index: 1;
                &:before{
                    width: 100%;
                }
            }
            #tab1:checked~#lang1{
                &:before{
                    transition: all .6s linear,
                        width 1s ease-in;
                }
            }
        }
    }
}
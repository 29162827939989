.ty{
    min-height: 100vh;
    display: grid;
    grid-template-rows: minmax(em(130), 16.6vh) 1fr minmax(em(80), 10vh);
    grid-template-columns: 7.5vw 40vw .8fr em(350) 1fr;
    align-items: center;
    @include r(1023){
        display: flex;
        flex-wrap: wrap;
        --smSize: var(--lgSize);
    }
    &__left{
        grid-row: 2/ 2;
        grid-column: 2/2;
        @include r(1023){
            // padding-bottom: em(150);
            flex: 1 1 100%;
            max-width: 100%;
            order: 2;
        }
        img{
            width: 100%;
            max-height: 75vh;
            object-fit: cover;
        }
    }
    &__right{
        grid-row: 2/2;
        grid-column: 4/5;
        align-self: center;
        @include r(1023){
            padding: 15vh 7.5vw 9vh ;
            flex: 1 1 100%;
            max-width: 100%;
            order: 1;
            text-align: center;
        }
    }


    &__title{
        margin-bottom: em(115,58);
        @include r(1023){
            font-size: calc(var(--h2Size_md) * 1.4);
            margin-bottom: .83em;
        }
    }
    &__text{
        margin-bottom: em(53);
    }
    &__btns{
        @extend %bottom-fixed-line;
        @include r(1023){
            br{
                display: none;
            }
        }
        .btn{
            @include rmin(1024){
                min-width: em(240);
                margin-bottom: em(12);
                &_primary{
                    @include animation-fill();
                }
            }
            @include r(1023){
                border: 0;
                font-size: em(30);
                box-shadow: none;
                position: relative;
                align-self: stretch;
                &_primary{
                    flex: 1;
                    order: 1;
                    text-transform: uppercase;
                    &::before{
                        content: "";
                        margin-left: -1em;
                        margin-right: .5em;
                        width: em(60,24);
                        height: em(35,24);
                        background-image: url('/img/decor/arrow.svg');
                        background-repeat: no-repeat;
                        transform: rotate(90deg);
                        position: static;
                    }
                }
                &_outline{
                    order: 2;
                    background-color: var(--color-primary);
                    font-weight: 400;
                    color: var(--color-bg);
                }

            }
        }
    }
}

.thank-you-page{
    &.layout{
        @include r(1023){
            height: auto;
        }
    }
}
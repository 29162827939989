$choices-bg-color: var(--color-primary);
$choices-bg-color-dropdown: var(--color-primary);
$choices-highlight-color: var(--color-primary-dark);
$choices-keyline-color: #ffffff;
$choices-text-color: var(--color-primary);

$choices-bg-hover: var(--color-primary-dark);

@import "choices.js/src/styles/choices.scss";

.choices[data-type*="select-one"] {
    margin-bottom: 0;
    &::before {
        content: "";
        position: absolute;
        width: 2em;
        height: 100%;
        background-color: $choices-keyline-color;
        top: 0;
        right: 0;
        z-index: 0;
        pointer-events: none;
    }
    &::after {
        z-index: 0;
        border-width: 8px;
        border-left-width: 4px;
        border-right-width: 4px;
    }

    .choices__input {
        border-bottom: 1px solid $choices-bg-color;
        background-color: $choices-bg-color;
        color: $choices-keyline-color;
        transition: none;
    }

    &.is-open {
        &::after {
            margin-top: -10.5px;
        }
    }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: $choices-bg-hover;
}

.choices {
    &__inner {
        padding-left: 0;
        display: flex;
        align-items: center;
    }
    &--dropdown {
        .choices__item {
            font-size: var(--smSize);
            padding: 0.5em;
        }
    }
    &-custom {
        &__line {
            font-size: var(--smSize);
        }
        &__address {
            font-size: 75%;
            font-weight: 300;
        }
    }
    &__list {
        &--single {
            padding: 0 1.42857em;
        }
        .choices__item--disabled {
            display: none;
        }
    }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted::after {
    display: none;
}

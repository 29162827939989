%button{
    @extend %reset-btn;
    padding: .5em 1em .55em 1em;
    display: inline-flex;
    flex: 0 0 auto;
    max-width: 100%;
    // font-size: var(--smSize);
    font-size: inherit;
    text-align: center;
    position: relative;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    min-height: em(38);
    min-width: em(160);
    @include  font-extra();
    letter-spacing: .02em;
}




.btn_primary{
    @extend %button;
    background-color: var(--color-text);
    color: var(--color-bg);

}

%bottom-fixed-line{
    @include r(1023){
        font-size: calc(var(--smSize) * .8);
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        margin: 0;
        background-color: var(--color-text);
        color: var(--color-bg);
        display: flex;
        align-items: center;
        min-height: em(150);
        overflow: hidden;
        z-index: 2;
    }
}


@mixin btn-animation-outline($c-1:var(--color-text), $c-2:var(--color-primary) ){
    @extend %button;
    @include rmin(1024){
        box-shadow: inset 0 0 0 2px $c-1;
        color: $c-1;
        transition: color 0.25s;
        &::before,
        &::after{
            content: '';
            position: absolute;
            border: 2px solid transparent;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform-origin: center;
        }
        &::before{
            top: 0;
            left: 0;
            border-top: 2px solid $c-2;
            border-bottom: 2px solid $c-2;
            transform: scale3d(0,1,1);
        }
        &::after {
            bottom: 0;
            right: 0;
            border-left: 2px solid $c-2;
            border-right: 2px solid $c-2;
            transform: scale3d(1,0,1);
        }
        &:hover {
            color: $c-2;
            &::before,
            &::after {
                width: 100%;
                height: 100%;
                transform: scale3d(1,1,1);
                transition: transform 0.5s;
            }

        }
        }

}

@mixin animation-fill(){
    // --
    overflow: hidden;
    position: relative;
    span{
        position: relative;
        z-index: 1;
    }
    &::before,
    &::after{
        content: '';
        position: absolute;
        width: 55%;
        height: 120%;
        background-color: var(--color-primary);
        transform: scaleX(0) skewX(0);
        transition: all .33s ease;
        z-index: 0;
    }
    &::before{
        right: calc(50% - 1px);
        transform-origin: 100% 50%;
    }
    &::after{
        left: calc(50% - 1px);
        transform-origin: 0% 50%;
    }
    &:hover{
        &::before,
        &::after{
            transform: scaleX(1) skewX(-30deg);
        }
    }

}

%btn-outline{
    @include btn-animation-outline;
}
.btn_outline{
    @extend %btn-outline;

}

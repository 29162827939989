// Font
$main-font: 'Soleil', sans-serif;

@mixin font-extra  {
        font-weight: 800;
        font-family: $main-font;
}
//TODO after right font available change mixin ^^



:root{
        --basicUnit: calc(calc(4vw + 4vh + 2vmin) / 10);  // in design 11.23px
        --basicSize: calc(var(--basicUnit) * 1.513);  // 17
        --lgSize: calc(var(--basicUnit) * 2.04); // 23
        --smSize: calc(var(--basicUnit) * 1.24); // 14

        --h1Size: calc(var(--basicUnit) * 13.21); //154
        --h2Size: calc(var(--basicUnit) * 5.16);  //58
        --h2Size_md: calc(var(--basicUnit) * 4.9);  //55
        --h3Size: calc(var(--basicUnit) * 4.035);  //45
        --stableMin: 10px;

        --footerPadding: calc(var(--basicSize) * 2.94); //padding-top + padding-bottom
        --footerLineHight: calc(var(--smSize) * 1.59); // inner font-size * line-height
        --footerTopGap: var(--lgSize); // footer top gap (under content)
        --footerHeight: calc(var(--footerPadding) + var(--footerLineHight) + var(--footerTopGap)); // only desktop but js was fixed this var


        --color-text: #000000; // also 020202 and 030303
        --color-bg: #ffffff;
        --color-primary: #5190ac;
        --color-primary-dark: #407186;
        --color-primary-light: #5191ac2d;
        --color-inactive: #d1d1d1;
        --color-gray: #d3d3d3;
}

$main-font-size: 17px;
$main-font-weight: 400;
$main-line-height: 1.59;


// Breakpoints
// $breakpoints: (
//         xs: 0,
//         sm: 576px,
//         md: 768px,
//         lg: 1024px,
//         xl: 1200px
// );

// Colors
// $color-primary: #4AC0C3;
// $color-secondary: #AD6AD9;
// $color-white: #FFFFFF;
// $color-dark: #202020;
// $color-text: #000033;
// $color-gray: #D5D5D5;


// Z-indexes
// $z-indexes: (
//         "modal",
//         "latest-activities",
//         "header",
//         "wrapper",
//         "footer"
// );

// Transitions
$transition-base: 0.33s cubic-bezier(.23, 1, .32, 1);
$transition-slow: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);
.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--color-text);
    z-index: 100;
    pointer-events: none;
    @include r(1023) {
        position: static;
    }

    .particular-page & {
        @include rmin(1024) {
            background-image: linear-gradient(
                to right,
                var(--color-bg),
                var(--color-bg) 62.7%,
                var(--color-text) 62.7%,
                var(--color-text)
            );
        }
        @include r(1023) {
            background-image: none;
            background-color: var(--color-bg);
        }
    }
    .particular-page.business-page & {
        @include r(1023) {
            @media (orientation: portrait) {
                background-color: var(--color-text);
            }
        }
    }

    .thank-you-page &,
    .welcome-page & {
        background-color: transparent;
    }
    .legal-page & {
        background-color: var(--color-bg);
    }

    .form-page & {
        padding: 1px;
        background-color: transparent;
        .footer__container {
            mix-blend-mode: unset;
        }
        @include rmin(1024) {
            background-image: linear-gradient(
                to right,
                var(--color-bg),
                var(--color-bg) 50%,
                var(--color-primary) 50%,
                var(--color-primary)
            );
        }
    }
    .form-page.step2 & {
        @include rmin(1024) {
            background-image: none;
        }
    }

    &__container {
        margin: em(25);
        // padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        mix-blend-mode: difference;
        opacity: 0.8;

        @include r(1023) {
            @media (orientation: portrait) {
                flex-wrap: wrap;
            }
            @media (orientation: landscape) and (max-width: 767px) {
                flex-wrap: wrap;
            }
        }
    }
    &__copy {
        font-size: var(--basicUnit);
        color: var(--color-bg);
        @include r(1023) {
            font-size: var(--stableMin);
            @media (orientation: portrait) {
                margin-bottom: 1em;
                flex: 1 1 100%;
                max-width: 100%;
                text-align: center;
            }
            @media (orientation: landscape) and (max-width: 767px) {
                margin-bottom: 1em;
                flex: 1 1 100%;
                max-width: 100%;
                text-align: center;
            }
        }
        .thank-you-page &,
        .form-page &,
        .welcome-page & {
            color: var(--color-text);
        }
    }
    &__nav {
        font-size: var(--basicUnit);
        color: var(--color-bg);
        pointer-events: all;
        @include r(1023) {
            font-size: var(--stableMin);
            @media (orientation: portrait) {
                flex: 1 1 100%;
                max-width: 100%;
            }
            @media (orientation: landscape) and (max-width: 767px) {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }
        .thank-you-page &,
        .form-page &,
        .welcome-page & {
            color: var(--color-text);
        }
        ul {
            @extend %reset-list;
            display: flex;
            @include r(1023) {
                @media (orientation: portrait) {
                    @include rmin(768) {
                        justify-content: center;
                    }
                    @include r(767) {
                        flex-wrap: wrap;
                    }
                }
                @media (orientation: landscape) and (max-width: 767px) {
                    justify-content: space-evenly;
                }
            }
        }
        li {
            &:not(:first-child) {
                @include rmin(768) {
                    margin-left: 1em;
                }
            }
            @include r(1023) {
                @media (orientation: portrait) {
                    @include rmin(768) {
                    }
                    @include r(767) {
                        flex: 0 1 50%;
                        margin-bottom: 0.5em;
                        &:nth-child(odd) {
                            text-align: right;
                            padding-right: 1em;
                        }
                        &:nth-child(even) {
                            padding-left: 1em;
                        }
                    }
                }
            }
        }
        a {
            color: currentColor;
            font-weight: 400;
            @include underlineOnHover;
        }
    }
}

.bottom-btn-is-active.footer-visible .layout {
    @include r(1023) {
        &.form-page {
            padding-bottom: calc(var(--basicUnit) * 12);
        }
    }
}

.footer-visible .layout {
    @include rmin(1024) {
        .intro {
            padding-bottom: var(--footerHeight);
        }
    }
    @include r(1023) {
        height: auto;

        .intro {
            min-height: calc(100vh - var(--footerHeight));
            // padding-bottom: var(--footerHeight);
        }
        &.particular-page {
            @media (max-width: 767px) and (orientation: landscape) {
                .particular__left .row__business {
                    height: auto;
                }
            }
        }
        &.welcome-page {
            --bottomLineHeight: calc(var(--basicUnit) * 1.703);
            @include r(768) {
                --bottomLineHeight: calc(
                    calc(var(--basicUnit) * 1.703) + 2.94em
                );
                .full-wrapper__inner-block {
                    margin-bottom: 2em;
                }
            }

            .full-wrapper__part-right {
                position: relative;
                min-height: 100vh;
            }
            .link-page {
                @media (min-width: 768px) and (orientation: landscape) {
                    position: static;
                    margin-bottom: 3em;
                }
            }
            @media (max-width: 767px) and (orientation: landscape) {
                padding-bottom: var(--bottomLineHeight);
            }
            @media (orientation: portrait) {
                padding-bottom: var(--bottomLineHeight);
            }
        }
        &.form-page {
            // @media (orientation: portrait) {
            @media (orientation: landscape) {
                padding-bottom: calc(var(--basicUnit) * 12);
            }
            .footer {
                background-color: var(--color-primary);
            }
            &.step2 {
                .footer {
                    background-color: var(--color-bg);
                }
                .btn_outline,
                .btn_primary,
                .calendar-submit-line button,
                .form__submit button,
                .calendar-submit-line {
                    min-height: calc(var(--basicUnit) * 12);
                }
            }
            .full-wrapper__part_right .form-step1 .full-wrapper__inner {
                padding-bottom: 2vh;
            }
            .full-wrapper__part_right .form-step1 .form__submit {
                min-height: calc(var(--basicUnit) * 12);
            }
            // }
        }
        &.thank-you-page {
            padding-bottom: calc(var(--basicUnit) * 12);
            .ty__btns {
                min-height: calc(var(--basicUnit) * 12);
            }
        }
    }
}
// .calendar-icon{
//     display: block !important;
//     z-index: 200;
//     position: fixed;
// }

.full-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    @include r(1023) {
        display: block;
        --smSize: var(--lgSize);
    }

    &__part {
        flex-basis: 50%;
        position: relative;
        min-height: 100vh;
        @include r(1023) {
            min-height: auto;
        }
        &_left {
        }
        &_right {
            flex: 1;
            background-color: var(--color-primary);
            color: var(--color-bg);

            .form-step1 {
                width: 100%;
                z-index: 2;
                background-color: var(--color-primary);
                @include rmin(1024) {
                    overflow-y: auto;
                    height: 100%;
                    max-height: 100vh;
                    position: absolute;
                    left: 0;
                    top: 0;

                    .footer-visible & .full-wrapper__inner {
                        // padding-bottom: var(--footerHeight);
                    }
                }
                @include r(1023) {
                    position: relative;
                }
                @include r(767) {
                    font-size: calc(var(--lgSize) * 1.2);
                    --basicSize: 12px;
                }
            }

            .form-step2 {
                padding-left: 24%;
                flex-basis: 100%;
                max-width: 100%;
                min-height: 100vh;
                flex-direction: column;
                display: flex;
                justify-content: center;
                background-color: var(--color-bg);
                z-index: 1;
                @include r(1023) {
                    padding-left: 13%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                @include r(767) {
                    font-size: calc(var(--lgSize) * 1.2);
                    --basicSize: 12px;
                }
                @include r(340) {
                    padding-top: 10vh;
                    padding-bottom: em(150);
                }
                @media (max-width: 1023px) and (orientation: landscape) {
                    padding-top: 13vh;
                    padding-bottom: em(150);
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: em(64);
                    margin-left: em(-32);
                    bottom: 0;
                    height: 20vh;
                    background-color: var(--color-primary);
                    opacity: 0.7;
                    @include r(767) {
                        width: em(32);
                        margin-left: em(-16);
                        height: 12vh;
                    }
                }
                .full-wrapper__inner {
                }
            }
        }
    }
    &__inner {
        display: block;
        .h2_md {
            @include r(1023) {
                font-size: calc(var(--h2Size_md) * 1.4);
            }
        }
        .full-wrapper__part_left & {
            padding: 185/900 * 100vh 7.5vw 0 8vw;
            position: absolute;
            @include r(1023) {
                padding-top: 15vh;
                padding-bottom: 9vh;
                text-align: center;
                position: relative;
            }
            @media (min-width: 1024px) and (max-height: 700px) {
                padding: 140/900 * 100vh 7.5vw 2vh 7vw;
            }
            @media (min-width: 1600px) and (max-width: 1700px) and (min-height: 1000px) {
                padding: 100/900 * 100vh 7.5vw 2vh 7vw;
            }
            @media (min-width: 1921px) and (min-height: 1000px) {
                padding: 14vh 7.5vw 2vh 7vw;
                .form__line {
                    margin-bottom: 1em;
                }
            }
        }

        .full-wrapper__part_right .form-step1.store-is-not-selected & {
            max-height: 56vh !important;
        }

        .full-wrapper__part_right .form-step1 & {
            // padding: 185/900 * 100vh 7.5vw 2vh 7vw;
            // position: absolute;
            @include r(1023) {
                padding-top: 6vh;
                padding-bottom: em(150);
                position: relative;
            }
            @media (min-width: 1024px) and (max-height: 700px) {
                padding: 140/900 * 100vh 7.5vw 2vh 7vw;
                @media (max-height: 600px) {
                    font-size: 10px;
                }
            }
            @media (min-width: 1600px) and (max-width: 1700px) and (min-height: 1000px) {
                padding: 100/900 * 100vh 7.5vw 2vh 7vw;
                .form__submit {
                    margin-top: 1.5em;
                }
            }
            @media (min-width: 1921px) and (min-height: 1000px) {
                padding: 14vh 7.5vw 2vh 7vw;
                .form__line {
                    margin-bottom: 1.2em;
                }
                .form__submit {
                    margin-top: 1.5em;
                }
            }
        }

        @media (min-width: 1700px) and (max-width: 2000px) and (max-height: 979px) {
            .full-wrapper__part_left &,
            .full-wrapper__part_right .form-step1 & {
                padding-top: 145/900 * 100vh;
            }
        }

        .full-wrapper__part_right .form-step2 & {
            display: flex;
            margin-left: em(-90);
            max-width: 94%;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            color: var(--color-text);
            @media (max-width: 1023px) and (orientation: portrait) {
                // padding-top: em(100);
                display: block;
            }
            @media (max-width: 1023px) and (orientation: landscape) {
                max-width: 84%;
            }
            @include r(1023) {
                position: relative;
                margin-left: 6%;
            }
        }
    }
}

.accordion-text {
    @include r(1023) {
        padding-bottom: 1.5em;
        line-height: 1.5;
        // max-height: 7.5em;
        // overflow: hidden;
        position: relative;
        // transition: $transition-base;
        // &::after{
        //     content: '... >';
        //     position: absolute;
        //     width: 100%;
        //     bottom: 0;
        //     line-height: 1.5;
        //     background-color: #fff;
        //     left: 0;
        // }
        // &.is-open{
        //     max-height: 10000px;
        //     &::after{
        //         opacity: 0;
        //     }
        // }
        p {
            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.form-step1 {
    display: flex;
    justify-content: center;
    align-items: center;

    .form__submit {
        @extend %bottom-fixed-line;
        @include r(1023) {
            button {
                margin: 0 calc(7.5vw - 1.5em) 0 auto;
                padding-right: 0;
                text-transform: uppercase;
                border: 0;
                font-size: em(30);
                box-shadow: none;
                position: static;
                &::before {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    height: em(150);
                }
                &::after {
                    content: "";
                    width: em(60, 24);
                    height: em(35, 24);
                    background-image: url("/img/decor/arrow.svg");
                    background-repeat: no-repeat;
                    transform: rotate(-90deg);
                    position: static;
                }
            }
        }
    }
}

.select-decorate {
    &::after {
        content: "";
        position: absolute;
        width: 2em;
        height: 100%;
        background-image: url("/img/decor/select-arrow.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        top: 0;
        right: 0;
        // z-index: 1;
        pointer-events: none;
    }
}
.select-with-group {
    optgroup {
        background-color: var(--color-primary);
        color: var(--color-bg);

        & {
            position: relative;
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 200%;
                top: 0%;
                left: 0;
                z-index: 2;
                background: #000;
            }
        }
    }
    option:not(.big) {
        font-size: 75%;
        font-weight: 300;
    }
}

.form-page {
    &.layout {
        @include r(1023) {
            height: auto;
        }
    }
    .overlay {
        top: 0;
        left: 0;
        height: 100%;
        width: 24%;
        position: absolute;
        z-index: 0;
        @extend %bg-cover;
        @include r(1023) {
            width: 13%;
        }
    }
    .header__lang li {
        // @include r(1023){
        //     color: var(--color-text);
        // }
    }
}

.calendar {
    @include rIn(768, 1023) {
        max-width: 22.5em;
    }
    @include r(767) {
        max-width: 18.5em;
    }
    &-icon {
        position: absolute;
        width: em(135);
        height: em(135);
        background-color: var(--color-text);
        top: 0;
        left: 24%;
        color: var(--color-bg);
        &__inner {
            @include abs-center();
            font-size: var(--smSize);
            text-align: center;
        }
        svg {
            width: em(55, 14);
            height: em(55, 14);
        }
        @include r(1023) {
            display: none;
        }
    }

    &-text {
        max-width: 32em;
        @include r(767) {
            max-width: 18.5em;
            margin-bottom: 2em;
        }
        h2 {
            margin-bottom: 0.5em;
            max-width: 15ch;
        }
        p {
            @include rmin(1024) {
                padding-left: em(130);
            }
        }
    }
    &-submit-line {
        margin-top: 30px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        line-height: 1.2;
        align-items: center;
        button {
            @extend %btn-outline;
            @include r(1023) {
                padding-left: 2em;
                text-transform: uppercase;
                border: 0;
                font-size: em(30);
                box-shadow: none;
                position: relative;
                align-self: stretch;
                background-color: var(--color-primary);

                padding-left: 0;
                padding-right: 0;
                flex: 0 1 50%;
                display: flex;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    height: em(150);
                }
                &::after {
                    content: "";
                    width: em(60, 24);
                    height: em(35, 24);
                    background-image: url("/img/decor/arrow.svg");
                    background-repeat: no-repeat;
                    transform: rotate(-90deg);
                    position: static;
                }
            }
        }
        @extend %bottom-fixed-line;

        @include r(1023) {
            .submit-text {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.6em;
                br {
                    display: none;
                }
            }
        }
    }
}

@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";
@import "flatpickr/dist/flatpickr.min"; // no need ./node_modules

// @import '@animxyz/core';

html,
body {
    height: 100%;
}

html {
    font-size: var(--basicSize);
    line-height: $main-line-height;
    @include r(768) {
        font-size: calc(var(--basicUnit) * 2.53); // 22px
    }
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    font-size: 1rem;
    line-height: $main-line-height;
    font-weight: $main-font-weight;
    color: var(--color-text);
    background-color: var(--color-bg);
    @extend %antialias;
    width: 100%;
    position: relative;
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

img,
svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button,
a {
    cursor: pointer;
}

strong,
.bold {
    font-weight: 700;
}

.layout {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 1176px;
    @include rmin(1500) {
        max-width: 1440px;
    }
}

.text {
    &_lg {
        font-size: var(--lgSize);
    }
    &_sm {
        font-size: var(--smSize);
    }
}

.logo__white {
    stroke: var(--color-bg);
    fill: var(--color-bg);
}
.icon-arrow {
    stroke: var(--color-text);
    transform: rotate(-90deg);
    width: calc(var(--basicUnit) * 8.7);
    height: calc(var(--basicUnit) * 5.0699);
    transition: all 0.6s linear, width 0.6s ease-in, height 0.6s ease-in,
        stroke 0.9s ease-in;
}
.underline {
    width: calc(var(--basicUnit) * 5.0699);
    height: calc(var(--basicUnit) / 2.23);
}
.bg-outside {
    position: absolute;
    top: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.link-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    max-width: fit-content;
    color: var(--color-text);
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
}

// Components
@import "components";
@import "pages";

// Utils
@import "utils/utils";

.invisible {
    visibility: hidden;
}

.footer {
    background: transparent !important;
}
